<template>
	<a class="skip-link block pin-left-0 pin-top-0 bg-matins white no-underline sz-2 p-2" :href="`#${targetId}`">
		{{ message }}
	</a>
</template>

<script>
	export default {
		name: 'SkipLink',
		props: {
			message: {
				type: String,
				default: 'Skip Menu'
			},
			targetId: {
				type: String,
				required: true
			}
		}
	};
</script>

<style lang="scss">
.skip-link {
	z-index: $z-skip-link;
	box-shadow: 0 0 0 3px rgba($casablanca, .3), inset 0 0 0 1px rgba(white, 40%);

	&:not(:focus) {
		@include visually-hidden;
	}
}
</style>